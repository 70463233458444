import React from 'react';

const WomanPuff = ({ color }) => (
<svg width="204" height="183" viewBox="0 0 204 183" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M166.032 102.589C163.16 91.4315 157.235 81.2948 148.924 73.3158C140.612 65.3369 130.242 59.8313 118.976 57.4167C107.71 55.002 95.9947 55.7737 85.1431 59.6453C74.2914 63.5168 64.733 70.3351 57.5398 79.3354C55.5587 79.097 53.5656 78.9724 51.5703 78.9623C37.893 78.9623 24.7759 84.3956 15.1046 94.0669C5.43328 103.738 0 116.855 0 130.533C0 144.21 5.43328 157.327 15.1046 166.998C24.7759 176.67 37.893 182.103 51.5703 182.103H163.858C174.227 182.11 184.188 178.07 191.622 170.842C199.056 163.614 203.374 153.769 203.658 143.405C203.941 133.04 200.167 122.974 193.14 115.351C186.112 107.727 176.386 103.148 166.032 102.589Z" fill={color}/>
<path d="M64.2876 111.974C64.253 111.864 64.2356 111.749 64.2362 111.633C64.2362 111.376 64.1976 111.054 64.1718 110.662C64.1718 110.443 64.1718 110.212 64.1332 109.954C64.0946 109.697 64.0689 109.421 64.0367 109.125C63.9531 108.526 63.9017 107.838 63.7344 107.105C63.6636 106.732 63.5865 106.339 63.5028 105.934C63.4192 105.529 63.2906 105.124 63.1812 104.693C62.9818 103.824 62.6473 102.93 62.3257 101.984C61.5527 99.9679 60.5899 98.0294 59.4503 96.1951C58.2734 94.3722 56.9273 92.6643 55.4299 91.094C54.7159 90.3993 54.0469 89.7174 53.3522 89.1642C53.0112 88.8812 52.7089 88.5917 52.368 88.3408L51.4095 87.6332C50.8177 87.1637 50.2195 86.8227 49.7177 86.4947L49.0101 86.0444L48.3669 85.7099L47.5178 85.2274C47.4124 85.1803 47.3148 85.1174 47.2283 85.0409C47.3371 85.0739 47.4427 85.117 47.5435 85.1695L48.4312 85.5941L49.0745 85.9093C49.2996 86.0315 49.5376 86.1795 49.8014 86.3338C50.3996 86.6715 50.9793 87.0408 51.5382 87.4403L52.5095 88.135C52.8504 88.3794 53.1528 88.6753 53.5194 88.9519C54.2581 89.5577 54.9646 90.2019 55.6357 90.8817C58.7303 94.0105 61.1074 97.7748 62.6023 101.914C62.9289 102.812 63.2081 103.726 63.4385 104.654C63.5414 105.091 63.6701 105.503 63.7473 105.94C63.8245 106.378 63.8888 106.745 63.9531 107.118C64.0741 107.795 64.16 108.477 64.2104 109.163C64.2104 109.466 64.2683 109.742 64.2812 110.006C64.294 110.27 64.2812 110.495 64.2812 110.713V111.691C64.2891 111.785 64.2912 111.88 64.2876 111.974Z" fill="#263238"/>
<path d="M43.1886 102.004C43.1436 102.049 42.4681 101.36 41.2588 100.466C38.0533 98.0855 34.245 96.6504 30.2654 96.3237C28.7409 96.1951 27.7889 96.253 27.7889 96.1951C27.9978 96.1338 28.2145 96.1035 28.4322 96.105C29.0404 96.0638 29.6508 96.0638 30.259 96.105C34.3153 96.2965 38.2086 97.7593 41.3874 100.286C41.8674 100.662 42.3209 101.07 42.7447 101.508C42.9113 101.656 43.0602 101.822 43.1886 102.004Z" fill="#263238"/>
<path d="M66.8091 146.022C66.4712 146.057 66.1307 146.057 65.7928 146.022C65.1495 146.022 64.1846 146.022 63.0139 145.964C60.6724 145.881 57.4368 145.688 53.8731 145.321C50.3095 144.955 47.0996 144.53 44.7839 144.15C43.626 143.964 42.6933 143.803 42.05 143.674C41.7116 143.626 41.3781 143.548 41.0529 143.443C41.3937 143.453 41.7334 143.487 42.0693 143.546L44.8225 143.919C47.1382 144.234 50.3481 144.639 53.9053 144.987C57.4625 145.334 60.6917 145.566 63.0268 145.707L65.7928 145.874C66.1346 145.9 66.4742 145.949 66.8091 146.022Z" fill="#263238"/>
<path d="M164.347 102.274C164.229 102.301 164.108 102.316 163.987 102.319L162.951 102.39C162.507 102.39 161.954 102.441 161.324 102.525C160.693 102.608 159.96 102.647 159.169 102.789L157.921 102.988C157.49 103.059 157.046 103.168 156.583 103.258C155.656 103.432 154.685 103.708 153.656 103.979C149.218 105.241 145.012 107.206 141.196 109.8C140.328 110.411 139.491 110.977 138.764 111.582C138.391 111.878 138.025 112.148 137.697 112.431L136.745 113.261C136.101 113.775 135.619 114.303 135.156 114.74C134.693 115.178 134.313 115.577 134.004 115.911L133.29 116.657C133.21 116.749 133.119 116.831 133.02 116.902C133.082 116.798 133.156 116.701 133.239 116.612C133.412 116.4 133.638 116.143 133.882 115.821C134.127 115.499 134.525 115.062 135.001 114.612C135.477 114.161 135.947 113.615 136.558 113.087L137.504 112.238C137.832 111.942 138.198 111.666 138.565 111.363C139.292 110.72 140.128 110.167 140.997 109.543C144.841 106.897 149.103 104.916 153.604 103.683C154.64 103.419 155.624 103.149 156.557 102.988C157.027 102.898 157.477 102.795 157.914 102.731L159.201 102.557C159.999 102.428 160.732 102.396 161.369 102.338C161.916 102.284 162.466 102.259 163.015 102.261H164.051C164.15 102.255 164.249 102.259 164.347 102.274Z" fill="#263238"/>
<path d="M161.169 182.45C161.169 182.547 136.307 182.617 105.649 182.617C74.9915 182.617 50.123 182.547 50.123 182.45C50.123 182.354 74.9786 182.283 105.649 182.283C136.32 182.283 161.169 182.36 161.169 182.45Z" fill="#263238"/>
<g opacity="0.3">
<path opacity="0.3" d="M180.274 123.251C178.988 115.911 169.255 108.456 160.867 110.135C153.559 111.588 145.692 113.287 138.469 115.114C135.484 115.866 132.396 112.245 129.9 110.45C128.614 109.511 127.816 108.006 126.999 106.59C122.348 98.6847 114.694 92.7538 106.145 89.441C97.5957 86.1282 88.1912 85.337 79.0762 86.334C72.0003 87.1124 64.2811 89.5503 60.9683 95.8543C59.6432 98.3952 59.1157 101.444 57.0637 103.438C54.3234 106.089 49.9879 105.876 46.4177 107.221C39.2775 109.91 35.6624 120.118 36.563 127.689C37.4635 135.261 44.1985 145.019 58.3567 146.575C58.3567 146.575 109.644 147.026 134.969 148.254C143.75 148.679 146.4 147.47 155.18 147.238C170.677 146.865 182.59 136.714 180.274 123.251Z" fill="black"/>
</g>
<path d="M86.062 160.142C86.924 159.923 87.8889 159.035 88.635 158.559C90.4619 157.384 92.2695 156.177 94.0577 154.938C87.6251 153.072 82.1896 151.599 75.7698 149.792C75.5494 152.365 75.789 154.957 76.4774 157.447C77.0692 159.434 78.2013 161.551 80.2083 162.078C82.0287 162.554 83.8813 161.551 85.4895 160.56L85.9333 160.283" fill="#E0E0E0"/>
<path d="M87.162 156.591C87.0655 156.591 87.1041 155.748 86.879 154.764C86.6538 153.78 86.2357 153.046 86.3515 152.995C86.7717 153.482 87.0656 154.064 87.2069 154.691C87.3483 155.319 87.3329 155.971 87.162 156.591Z" fill="#263238"/>
<path d="M88.5449 156.25C88.4788 155.773 88.4444 155.291 88.442 154.809C88.3423 154.338 88.2756 153.861 88.2426 153.381C88.5395 153.791 88.7236 154.272 88.7766 154.775C88.8297 155.279 88.7498 155.787 88.5449 156.25Z" fill="#263238"/>
<path d="M80.0668 150.988C80.1247 150.988 79.9382 151.631 79.9253 152.731C79.9048 154.128 80.2531 155.506 80.9352 156.726C81.5873 157.962 82.4796 159.056 83.5598 159.942C84.0424 160.263 84.5526 160.541 85.0843 160.772C84.9219 160.781 84.7595 160.755 84.6083 160.695C84.1878 160.569 83.7887 160.38 83.4247 160.135C82.2648 159.275 81.313 158.164 80.6393 156.887C79.929 155.617 79.6006 154.17 79.6938 152.718C79.7145 152.284 79.7814 151.852 79.8932 151.432C79.922 151.274 79.981 151.123 80.0668 150.988Z" fill="#455A64"/>
<path d="M85.0842 152.474C85.6476 153.663 86.0002 154.941 86.1263 156.25C86.3534 157.54 86.3534 158.859 86.1263 160.148C86.0234 160.148 86.0941 158.405 85.8047 156.289C85.5152 154.172 84.9813 152.506 85.0842 152.474Z" fill="#455A64"/>
<path d="M83.6305 159.318C83.2479 159.1 82.9027 158.822 82.6077 158.495C81.8232 157.773 81.1702 156.919 80.6779 155.973C80.1863 155.014 79.983 153.933 80.0925 152.86C80.1113 152.412 80.2587 151.979 80.5171 151.612C80.5685 151.612 80.3756 152.095 80.3305 152.899C80.293 153.919 80.5143 154.933 80.9738 155.845C81.4542 156.758 82.0557 157.602 82.7621 158.353C83.0822 158.646 83.3731 158.969 83.6305 159.318Z" fill={color}/>
<path d="M92.6232 155.832C91.9299 156.394 91.1693 156.867 90.359 157.241C89.6008 157.71 88.7887 158.086 87.9403 158.36C88.6304 157.792 89.3916 157.316 90.2046 156.945C90.9617 156.475 91.7741 156.101 92.6232 155.832Z" fill={color}/>
<path d="M82.3826 159.408C81.8492 159.99 81.1767 160.426 80.4284 160.676C79.6801 160.925 78.8805 160.981 78.1049 160.836C78.1049 160.74 79.1405 160.759 80.3241 160.354C81.5077 159.949 82.3247 159.331 82.3826 159.408Z" fill="#455A64"/>
<path d="M80.2019 156.115C80.2019 156.212 79.3335 156.334 78.3686 156.758C77.4037 157.183 76.7283 157.775 76.6575 157.704C77.0309 157.128 77.582 156.69 78.2271 156.456C78.829 156.126 79.524 156.006 80.2019 156.115Z" fill="#455A64"/>
<path d="M79.7773 153.188C79.0842 152.949 78.3775 152.752 77.661 152.596C76.9282 152.558 76.1939 152.558 75.4611 152.596C76.1494 152.231 76.9439 152.12 77.706 152.281C78.4835 152.323 79.2194 152.645 79.7773 153.188Z" fill="#455A64"/>
<path d="M129.025 143.887C129.064 143.945 104.897 149.773 104.897 149.773L101.346 141.455L102.973 138.94C102.973 138.94 113.188 136.631 121.499 131.928L129.025 143.887Z" fill="#FFC69D"/>
<path d="M72.3155 120.189C72.3155 120.189 71.2091 118.574 66.5262 116.278C62.6344 114.348 52.6446 112.779 48.5984 120.594C44.5523 128.41 47.6593 140.4 54.3878 142.961C61.1163 145.521 72.3155 149.027 76.902 150.506C81.4884 151.986 96.5729 155.761 96.5729 155.761L102.774 152.127L103.507 140.548C103.507 140.548 94.9583 131.768 87.683 129.343C80.4078 126.918 72.3155 120.189 72.3155 120.189Z" fill="#263238"/>
<path d="M58.3567 114.734C58.421 114.637 65.2139 114.83 70.1734 116.702C72.0389 117.403 73.8657 118.156 73.8657 118.156L76.606 110.347L97.1454 103.503H119.885L127.282 118.156V118.896C127.282 118.896 136.166 119.044 140.012 117.268C143.859 115.493 155.843 110.308 158.95 109.273C162.057 108.237 175.971 106.249 179.078 114.715C182.185 123.18 179.985 132.012 174.787 135.241C172.336 136.759 130.987 147.463 130.987 147.463L121.216 131.922C121.216 131.922 106.331 139.3 101.346 138.496C96.3606 137.692 58.3567 114.734 58.3567 114.734Z" fill="#263238"/>
<path d="M100.297 107.278C100.341 107.376 100.376 107.477 100.4 107.581C100.458 107.812 100.535 108.102 100.625 108.449C100.812 109.208 101.05 110.321 101.269 111.698C101.77 114.451 102.272 118.291 102.658 122.543C103.044 126.795 103.25 130.655 103.334 133.479C103.379 134.874 103.391 136.007 103.391 136.791C103.391 137.152 103.391 137.435 103.391 137.692C103.409 137.796 103.409 137.903 103.391 138.007C103.371 137.903 103.358 137.798 103.353 137.692C103.353 137.447 103.353 137.152 103.308 136.791C103.263 135.961 103.211 134.862 103.141 133.491C102.999 130.7 102.748 126.846 102.369 122.595C101.989 118.343 101.52 114.522 101.082 111.762C100.87 110.385 100.664 109.273 100.516 108.507C100.452 108.153 100.394 107.864 100.349 107.619C100.313 107.509 100.296 107.394 100.297 107.278Z" fill="#455A64"/>
<path d="M127.057 119.256C127.057 119.346 121.911 119.996 115.536 120.704C109.161 121.411 103.996 121.913 103.957 121.816C103.919 121.72 109.104 121.077 115.478 120.369C121.853 119.662 127.044 119.166 127.057 119.256Z" fill="#455A64"/>
<path d="M123.661 119.288C123.415 119.299 123.168 119.28 122.927 119.23C122.458 119.166 121.782 119.082 120.94 118.999C118.755 118.761 116.557 118.66 114.359 118.696C112.163 118.739 109.972 118.915 107.798 119.224L105.823 119.526C105.582 119.581 105.337 119.611 105.09 119.616C105.315 119.515 105.553 119.444 105.797 119.404C106.254 119.282 106.923 119.14 107.759 118.992C112.125 118.229 116.582 118.151 120.972 118.761C121.808 118.883 122.483 118.999 122.947 119.102C123.19 119.14 123.429 119.202 123.661 119.288Z" fill="#455A64"/>
<path d="M126.806 118.401C125.396 117.708 123.92 117.161 122.4 116.767C120.854 116.483 119.282 116.36 117.71 116.4C118.155 116.245 118.623 116.167 119.093 116.168C121.37 116.037 123.639 116.538 125.648 117.616C126.074 117.814 126.464 118.079 126.806 118.401Z" fill="#455A64"/>
<path d="M107.869 131.117C107.284 131.704 106.57 132.144 105.784 132.404C105.041 132.762 104.223 132.937 103.398 132.912C103.398 132.816 104.434 132.584 105.656 132.089C106.878 131.594 107.811 131.04 107.869 131.117Z" fill="#455A64"/>
<path d="M106.775 133.999C105.71 134.57 104.478 134.748 103.295 134.501C103.862 134.344 104.444 134.251 105.032 134.225C105.603 134.087 106.188 134.012 106.775 133.999Z" fill="#455A64"/>
<path d="M141.723 117.834C141.63 117.913 141.529 117.982 141.421 118.04L140.527 118.574L137.195 120.504L126.144 126.718L115.041 132.848L111.657 134.675L110.725 135.158C110.618 135.217 110.506 135.266 110.39 135.305C110.483 135.226 110.584 135.157 110.692 135.1L111.593 134.559L114.925 132.629L125.976 126.416L137.079 120.285L140.463 118.465L141.389 117.982C141.492 117.916 141.605 117.866 141.723 117.834Z" fill="#455A64"/>
<path d="M159.072 114.56C158.936 114.605 158.796 114.639 158.654 114.663L157.458 114.908C156.416 115.126 154.917 115.474 153.09 115.988C148.34 117.371 143.731 119.2 139.324 121.45C134.139 124.023 129.675 126.789 126.407 128.763L122.548 131.118L121.493 131.761C121.372 131.837 121.245 131.904 121.113 131.96C121.22 131.863 121.336 131.777 121.461 131.703L122.477 131.021C123.365 130.436 124.651 129.593 126.259 128.577C129.476 126.544 133.979 123.772 139.157 121.16C143.579 118.9 148.219 117.094 153.006 115.77C154.852 115.281 156.364 114.972 157.413 114.798C157.934 114.695 158.345 114.65 158.622 114.612C158.769 114.576 158.921 114.558 159.072 114.56Z" fill="#455A64"/>
<path d="M169.255 111.633C169.255 111.691 168.509 111.633 167.325 111.633C165.766 111.68 164.217 111.913 162.713 112.328C161.212 112.748 159.765 113.344 158.403 114.103C157.355 114.695 156.75 115.133 156.718 115.088C156.686 115.043 156.84 114.94 157.104 114.727C157.478 114.428 157.872 114.153 158.281 113.904C159.63 113.066 161.092 112.427 162.623 112.006C164.152 111.583 165.733 111.377 167.319 111.395C167.799 111.4 168.278 111.434 168.753 111.498C168.926 111.519 169.095 111.564 169.255 111.633Z" fill="#455A64"/>
<path d="M165.679 114.355C165.679 114.451 163.955 114.226 161.819 114.303C159.683 114.38 158.024 114.715 158.004 114.618C159.224 114.18 160.511 113.963 161.806 113.975C163.109 113.872 164.42 114 165.679 114.355Z" fill="#455A64"/>
<path d="M158.217 111.73C158.262 111.82 156.782 112.605 155.135 113.833C153.489 115.062 152.299 116.246 152.228 116.175C152.97 115.152 153.887 114.267 154.936 113.563C155.916 112.767 157.026 112.147 158.217 111.73Z" fill="#455A64"/>
<path d="M179.978 119.069C179.957 119.116 179.933 119.161 179.907 119.204L179.695 119.584C179.496 119.925 179.2 120.407 178.769 121.006C177.562 122.717 176.102 124.235 174.44 125.508C171.963 127.359 169.288 128.928 166.463 130.185C163.298 131.645 159.69 132.932 155.907 134.257L136.519 141.043L130.627 143.057L129.025 143.591L128.601 143.726C128.554 143.744 128.504 143.755 128.453 143.758L128.588 143.693L129 143.526L130.589 142.934L136.442 140.818L155.798 133.942C159.58 132.617 163.183 131.369 166.341 129.902C169.151 128.666 171.818 127.131 174.298 125.322C175.962 124.074 177.441 122.597 178.692 120.935C179.129 120.35 179.451 119.873 179.663 119.552L179.907 119.185C179.955 119.104 179.978 119.065 179.978 119.069Z" fill="#455A64"/>
<path d="M102.086 152.539C101.926 152.493 101.769 152.437 101.616 152.371L100.291 151.837C99.1459 151.368 97.4927 150.667 95.4665 149.772C91.4075 147.991 85.8175 145.443 79.7709 142.478C73.7242 139.512 68.2758 136.689 64.4355 134.437C62.5057 133.331 60.9876 132.392 59.9327 131.716L58.7426 130.925C58.6001 130.84 58.4665 130.741 58.3438 130.629C58.4958 130.696 58.6421 130.776 58.7812 130.867L59.997 131.607C61.0584 132.25 62.6022 133.157 64.5384 134.225C68.398 136.393 73.84 139.197 79.906 142.176C85.9719 145.154 91.5233 147.72 95.5501 149.554L100.342 151.747L101.629 152.333C101.788 152.386 101.941 152.455 102.086 152.539Z" fill="#455A64"/>
<path d="M59.952 118.793C60.1232 118.642 60.3162 118.519 60.5245 118.426C61.0663 118.138 61.6298 117.893 62.2098 117.693C64.1422 117.026 66.2009 116.806 68.2307 117.05C70.2582 117.243 72.2333 117.806 74.0587 118.709C74.6035 118.997 75.1129 119.346 75.5768 119.751C75.7565 119.888 75.9148 120.05 76.0464 120.234C75.3766 119.749 74.6761 119.309 73.9493 118.915C72.1168 118.09 70.1608 117.573 68.16 117.384C66.1794 117.142 64.17 117.317 62.2613 117.899C60.8139 118.368 59.9777 118.851 59.952 118.793Z" fill="#455A64"/>
<path d="M103.501 140.554C103.389 140.493 103.287 140.414 103.198 140.323L102.375 139.622C101.661 138.978 100.606 138.142 99.2939 137.087C96.6694 134.984 92.9642 132.166 88.8795 129.046L85.9076 126.763C84.9363 126.042 83.9778 125.425 83 124.833C81.0703 123.694 79.3334 122.723 77.9376 121.765C76.8208 121.029 75.78 120.185 74.8306 119.243C74.5725 118.99 74.3277 118.724 74.0973 118.445C74.0075 118.355 73.9297 118.253 73.8657 118.143C73.8657 118.143 74.2324 118.516 74.9207 119.153C75.8964 120.048 76.949 120.855 78.0662 121.565C79.4685 122.492 81.2375 123.437 83.1609 124.569C84.1193 125.142 85.0906 125.759 86.1006 126.499C87.1105 127.239 88.0304 128.004 89.0789 128.783C93.1636 131.902 96.8495 134.746 99.4418 136.907C100.728 137.981 101.777 138.875 102.459 139.519C102.813 139.84 103.07 140.098 103.243 140.278C103.338 140.362 103.424 140.454 103.501 140.554Z" fill="#455A64"/>
<path d="M86.538 124.518C87.3169 124.76 88.0814 125.046 88.828 125.374C90.2368 125.92 92.1859 126.66 94.3794 127.336C96.5729 128.011 98.5799 128.564 100.04 128.924C100.84 129.097 101.63 129.312 102.407 129.568C102.191 129.577 101.974 129.555 101.764 129.503C101.346 129.445 100.741 129.336 100.001 129.182C98.0727 128.779 96.1661 128.277 94.2893 127.676C92.4095 127.084 90.561 126.397 88.7508 125.618C88.0625 125.316 87.5029 125.058 87.1234 124.872C86.9153 124.777 86.7189 124.658 86.538 124.518Z" fill="#455A64"/>
<path d="M93.6525 118.819C92.8913 118.997 92.1202 119.13 91.3432 119.218C89.9152 119.43 87.9403 119.732 85.7725 120.118C83.6048 120.504 81.6492 120.909 80.2341 121.205C79.4765 121.397 78.7072 121.538 77.9312 121.63C78.1185 121.534 78.3174 121.463 78.523 121.417C78.9089 121.302 79.475 121.147 80.1762 120.974C81.5849 120.62 83.534 120.182 85.7147 119.79C87.8953 119.398 89.8701 119.147 91.311 118.98C92.0315 118.902 92.5976 118.851 93.0221 118.825C93.2308 118.792 93.4432 118.79 93.6525 118.819Z" fill="#455A64"/>
<path d="M90.6034 115.042C90.6034 115.094 89.7157 115.171 88.3005 115.383C84.5963 115.93 80.9988 117.045 77.6352 118.69C76.3487 119.333 75.5704 119.764 75.5446 119.712C75.6959 119.567 75.867 119.444 76.0528 119.346C76.3873 119.121 76.889 118.825 77.5259 118.477C79.1921 117.589 80.9389 116.861 82.7427 116.303C84.5447 115.747 86.3946 115.359 88.2683 115.145C88.9888 115.068 89.5549 115.03 89.973 115.023C90.1828 114.996 90.3956 115.003 90.6034 115.042Z" fill="#455A64"/>
<path d="M116.45 147.521L112.59 156.527C114.012 157.813 119.415 158.637 121.319 158.54C123.223 158.444 125.976 158.463 127.874 158.251C130.292 158.055 132.722 158.073 135.136 158.302C137.553 158.531 139.987 158.486 142.392 158.167C144.804 157.836 147.053 156.761 148.825 155.092C149.682 154.335 150.33 153.371 150.707 152.292C151.083 151.212 151.176 150.054 150.977 148.928C150.777 147.803 150.292 146.747 149.567 145.863C148.842 144.979 147.902 144.296 146.837 143.88C144.897 143.055 142.772 142.764 140.681 143.037C138.602 143.312 136.552 143.777 134.558 144.427C132.415 145.07 130.222 145.713 127.996 145.52C127.546 145.482 127.096 145.52 126.652 145.418C123.744 144.877 120.715 144.851 118.096 146.498C117.582 146.826 116.996 147.116 116.45 147.521Z" fill="#E0E0E0"/>
<path d="M117.009 148.531C117.112 148.413 117.227 148.305 117.35 148.209C117.697 147.923 118.067 147.667 118.457 147.444C119.908 146.609 121.583 146.247 123.249 146.408C124.291 146.511 125.41 146.723 126.62 146.904C127.893 147.131 129.19 147.193 130.479 147.09C131.895 146.9 133.291 146.579 134.648 146.132C136.125 145.643 137.653 145.319 139.202 145.167C140.761 145.016 142.335 145.151 143.846 145.566C144.563 145.773 145.258 146.053 145.918 146.402C146.559 146.727 147.138 147.163 147.629 147.688C148.532 148.671 149.168 149.869 149.475 151.168C149.625 151.76 149.645 152.376 149.533 152.976C149.411 153.526 149.193 154.051 148.889 154.526C148.381 155.333 147.697 156.015 146.889 156.52C146.288 156.903 145.653 157.228 144.991 157.492C144.451 157.717 144.026 157.865 143.737 157.968C143.592 158.028 143.441 158.071 143.287 158.096C143.422 158.02 143.564 157.955 143.711 157.903C143.994 157.781 144.412 157.614 144.94 157.376C145.581 157.089 146.199 156.752 146.786 156.366C147.557 155.862 148.205 155.191 148.684 154.404C148.979 153.936 149.175 153.413 149.26 152.866C149.345 152.32 149.318 151.762 149.179 151.226C148.868 149.984 148.249 148.84 147.378 147.901C146.367 146.906 145.107 146.201 143.73 145.862C142.264 145.466 140.74 145.335 139.228 145.476C136.088 145.707 133.303 147.142 130.511 147.405C129.199 147.502 127.881 147.428 126.588 147.187C125.378 146.987 124.265 146.762 123.236 146.646C121.611 146.478 119.972 146.796 118.527 147.56C118.005 147.856 117.498 148.181 117.009 148.531Z" fill="#455A64"/>
<path d="M143.312 158.135C143.312 158.135 143.171 158.013 142.965 157.743C142.835 157.584 142.717 157.416 142.611 157.241C142.461 157.014 142.325 156.778 142.206 156.533C141.878 155.905 141.609 155.249 141.402 154.571C141.15 153.732 140.978 152.87 140.887 151.998C140.81 151.132 140.81 150.26 140.887 149.393C140.955 148.688 141.086 147.99 141.28 147.309C141.349 147.044 141.435 146.784 141.537 146.531C141.603 146.337 141.683 146.148 141.775 145.964C141.929 145.662 142.019 145.501 142.039 145.514C142.058 145.527 141.781 146.209 141.511 147.367C141.35 148.041 141.244 148.727 141.196 149.419C141.145 150.265 141.158 151.115 141.235 151.96C141.332 152.803 141.493 153.637 141.717 154.455C141.906 155.117 142.147 155.762 142.437 156.385C142.907 157.537 143.351 158.103 143.312 158.135Z" fill="#263238"/>
<path d="M118.823 146.26C118.907 146.305 118.823 146.64 118.637 147.026C118.45 147.412 118.302 147.72 118.212 147.669C118.122 147.617 118.161 147.283 118.328 146.878C118.495 146.472 118.746 146.215 118.823 146.26Z" fill="#455A64"/>
<path d="M121.57 145.192C121.654 145.231 121.57 145.559 121.461 145.945C121.351 146.331 121.191 146.64 121.1 146.588C121.01 146.537 121.01 146.215 121.139 145.81C121.268 145.405 121.486 145.154 121.57 145.192Z" fill="#455A64"/>
<path d="M125.102 145.122C125.185 145.154 125.14 145.534 124.999 145.971C124.857 146.408 124.664 146.743 124.581 146.711C124.497 146.679 124.535 146.305 124.677 145.868C124.819 145.431 125.012 145.096 125.102 145.122Z" fill="#455A64"/>
<path d="M128.414 145.54C128.504 145.54 128.472 145.945 128.344 146.389C128.215 146.832 128.035 147.173 127.951 147.148C127.868 147.122 127.893 146.742 128.022 146.292C128.151 145.842 128.324 145.514 128.414 145.54Z" fill="#455A64"/>
<path d="M131.206 147.289C131.116 147.289 131.039 146.839 131.045 146.286C131.052 145.733 131.123 145.289 131.219 145.289C131.315 145.289 131.38 145.739 131.38 146.286C131.38 146.833 131.296 147.289 131.206 147.289Z" fill="#455A64"/>
<path d="M134.307 146.498C134.21 146.498 134.081 146.112 134.011 145.611C133.94 145.109 133.959 144.691 134.049 144.678C134.139 144.665 134.274 145.064 134.345 145.565C134.416 146.067 134.41 146.485 134.307 146.498Z" fill="#455A64"/>
<path d="M137.176 143.687C137.266 143.687 137.414 144.047 137.504 144.53C137.594 145.012 137.594 145.418 137.504 145.43C137.414 145.443 137.266 145.07 137.176 144.588C137.086 144.105 137.086 143.7 137.176 143.687Z" fill="#455A64"/>
<path d="M140.881 143.005C140.971 143.005 140.932 143.552 140.804 144.183C140.675 144.813 140.482 145.315 140.392 145.295C140.302 145.276 140.34 144.749 140.475 144.118C140.611 143.488 140.791 142.986 140.881 143.005Z" fill="#455A64"/>
<path d="M144.058 143.095C144.142 143.134 143.917 143.739 143.55 144.382C143.184 145.025 142.823 145.578 142.746 145.533C142.817 145.072 142.99 144.632 143.254 144.247C143.615 143.571 143.956 143.057 144.058 143.095Z" fill="#455A64"/>
<path d="M147.294 143.944C147.371 143.996 147.075 144.588 146.651 145.269C146.226 145.951 145.782 146.466 145.705 146.414C145.628 146.363 145.924 145.771 146.349 145.089C146.773 144.407 147.217 143.893 147.294 143.944Z" fill="#455A64"/>
<path d="M149.378 145.9C149.449 145.951 149.166 146.485 148.735 147.096C148.304 147.708 147.905 148.158 147.834 148.106C147.764 148.055 148.04 147.515 148.478 146.904C148.915 146.292 149.301 145.842 149.378 145.9Z" fill="#455A64"/>
<path d="M150.832 148.383C150.909 148.435 150.62 148.956 150.131 149.496C149.642 150.036 149.153 150.371 149.095 150.307C149.037 150.242 149.41 149.798 149.886 149.271C150.362 148.743 150.755 148.325 150.832 148.383Z" fill="#455A64"/>
<path d="M150.62 152.172C150.691 152.223 150.53 152.538 150.15 152.705C149.771 152.873 149.423 152.783 149.436 152.705C149.449 152.628 149.713 152.545 150.015 152.41C150.317 152.274 150.549 152.107 150.62 152.172Z" fill="#455A64"/>
<path d="M148.561 155.414C148.561 155.497 148.503 155.607 148.362 155.587C148.22 155.568 148.175 155.427 148.233 155.356C148.254 155.325 148.282 155.3 148.314 155.282C148.346 155.264 148.383 155.254 148.42 155.253C148.455 155.266 148.486 155.287 148.511 155.315C148.536 155.343 148.553 155.377 148.561 155.414Z" fill="#455A64"/>
<path d="M126.131 156.694C126.588 155.731 126.939 154.722 127.179 153.683C127.316 152.617 127.338 151.538 127.244 150.467C127.616 151.508 127.709 152.628 127.514 153.716C127.318 154.804 126.842 155.822 126.131 156.668V156.694Z" fill="#263238"/>
<path d="M128.781 156.391C129.205 155.504 129.528 154.572 129.746 153.612C129.859 152.634 129.859 151.645 129.746 150.666C130.134 151.615 130.249 152.654 130.08 153.665C129.911 154.676 129.463 155.62 128.787 156.391H128.781Z" fill="#263238"/>
<path d="M132.12 156.411C133.277 154.567 133.687 152.352 133.265 150.216C133.448 150.489 133.563 150.803 133.599 151.13C133.749 151.907 133.749 152.706 133.599 153.484C133.46 154.264 133.17 155.009 132.744 155.677C132.428 156.186 132.152 156.437 132.12 156.411Z" fill="#263238"/>
<path d="M135.889 156.186C136.275 155.184 136.566 154.148 136.757 153.091C136.833 152.02 136.812 150.943 136.693 149.875C137.091 150.913 137.226 152.032 137.086 153.135C136.945 154.237 136.534 155.287 135.889 156.192V156.186Z" fill="#263238"/>
<path d="M125.455 153.362C125.283 153.437 125.094 153.463 124.909 153.439C124.398 153.406 123.905 153.244 123.474 152.969C122.856 152.552 122.316 152.029 121.879 151.425C121.582 151.085 121.347 150.695 121.184 150.274C121.103 150.032 121.083 149.773 121.126 149.521C121.175 149.251 121.316 149.005 121.525 148.827C121.728 148.664 121.968 148.554 122.224 148.505C122.48 148.457 122.743 148.472 122.992 148.55C123.429 148.694 123.825 148.944 124.143 149.277C124.673 149.81 125.083 150.451 125.346 151.155C125.524 151.623 125.631 152.115 125.661 152.615C125.69 152.798 125.679 152.984 125.629 153.162C125.53 152.503 125.362 151.856 125.127 151.232C124.846 150.577 124.437 149.984 123.924 149.489C123.642 149.193 123.29 148.972 122.902 148.846C122.705 148.787 122.498 148.777 122.296 148.815C122.095 148.853 121.905 148.938 121.744 149.065C121.597 149.201 121.496 149.378 121.452 149.573C121.408 149.769 121.425 149.972 121.499 150.158C121.651 150.546 121.869 150.905 122.143 151.219C122.548 151.808 123.048 152.325 123.622 152.75C124.162 153.125 124.798 153.337 125.455 153.362Z" fill="#263238"/>
<path d="M125.301 153.265C125.289 153.434 125.248 153.599 125.179 153.754C125.035 154.199 124.849 154.63 124.626 155.041C124.3 155.666 123.805 156.188 123.198 156.546C122.824 156.757 122.404 156.872 121.975 156.88C121.726 156.897 121.477 156.85 121.252 156.743C121.027 156.636 120.833 156.473 120.689 156.269C120.576 156.049 120.529 155.8 120.555 155.554C120.58 155.307 120.675 155.073 120.83 154.88C121.08 154.534 121.411 154.255 121.795 154.069C122.426 153.789 123.093 153.599 123.776 153.503C124.384 153.352 125.004 153.26 125.629 153.227C125.044 153.447 124.442 153.617 123.828 153.735C123.175 153.861 122.54 154.066 121.937 154.346C121.604 154.514 121.317 154.762 121.1 155.066C120.986 155.209 120.913 155.381 120.892 155.563C120.87 155.745 120.9 155.93 120.978 156.096C121.09 156.244 121.237 156.363 121.405 156.442C121.573 156.522 121.757 156.56 121.943 156.552C122.319 156.551 122.688 156.456 123.017 156.276C123.587 155.951 124.061 155.483 124.394 154.918C124.979 153.928 125.217 153.233 125.301 153.265Z" fill="#263238"/>
<path d="M125.037 156.006C124.96 155.941 125.333 155.446 125.442 154.719C125.552 153.992 125.372 153.433 125.442 153.381C125.606 153.57 125.719 153.796 125.773 154.04C125.827 154.284 125.82 154.537 125.751 154.777C125.642 155.607 125.089 156.076 125.037 156.006Z" fill="#263238"/>
<path d="M126.472 151.509C126.555 151.509 126.472 152.023 126.195 152.551C125.919 153.078 125.552 153.413 125.481 153.361C125.41 153.31 125.635 152.892 125.899 152.397C126.163 151.901 126.382 151.477 126.472 151.509Z" fill="#263238"/>
<path d="M136.77 146.723C136.329 147.016 135.848 147.243 135.342 147.399C132.937 148.296 130.372 148.681 127.81 148.531C127.281 148.528 126.755 148.453 126.247 148.306C128.03 148.327 129.814 148.235 131.586 148.029C133.34 147.707 135.072 147.271 136.77 146.723Z" fill={color}/>
<path d="M135.175 157.151C134.014 157.4 132.831 157.538 131.644 157.562C130.465 157.698 129.276 157.724 128.093 157.639C129.254 157.39 130.437 157.252 131.624 157.228C132.803 157.092 133.992 157.066 135.175 157.151Z" fill={color}/>
<path d="M143.711 157.401C144.517 157.288 145.289 157.003 145.975 156.565C146.7 156.07 147.281 155.394 147.661 154.603C148.097 153.627 148.219 152.538 148.008 151.49C147.804 150.434 147.309 149.456 146.58 148.666C145.978 148.026 145.257 147.511 144.457 147.148C143.698 146.839 142.909 146.608 142.103 146.459C142.326 146.419 142.555 146.419 142.778 146.459C143.386 146.53 143.981 146.684 144.547 146.916C145.398 147.261 146.167 147.78 146.805 148.441C147.59 149.264 148.121 150.296 148.336 151.412C148.564 152.531 148.424 153.693 147.937 154.725C147.523 155.556 146.889 156.256 146.104 156.752C145.58 157.082 144.993 157.301 144.38 157.395C144.16 157.439 143.933 157.441 143.711 157.401Z" fill={color}/>
<path d="M103.018 141.59L119.743 149.02C119.906 151.146 119.553 153.28 118.714 155.24C117.492 157.749 113.78 157.247 113.78 157.247L99.0623 153.825L102.343 151.895L103.018 141.59Z" fill="#FFC69D"/>
<path d="M117.968 156.559C117.306 156.096 116.701 155.556 116.167 154.951C115.632 154.338 115.332 153.557 115.318 152.744C115.39 152.012 115.637 151.309 116.038 150.692C116.38 149.947 116.968 149.343 117.704 148.981C118.63 148.634 119.634 149.033 120.541 149.476L120.715 153.207L117.968 156.559Z" fill="#E0E0E0"/>
<path d="M119.769 149.02C119.73 149.104 115.954 147.508 111.329 145.456C106.704 143.404 102.967 141.674 103.018 141.597C103.07 141.52 106.839 143.102 111.464 145.154C116.089 147.206 119.808 148.93 119.769 149.02Z" fill="#EB996E"/>
<path d="M138.809 81.5735C138.971 81.5821 139.132 81.5588 139.284 81.5049C139.436 81.451 139.576 81.3675 139.695 81.2592C139.815 81.151 139.912 81.0202 139.981 80.8743C140.049 80.7284 140.089 80.5703 140.096 80.4092C140.283 75.1216 136.796 63.144 135.124 58.1716C134.101 55.1354 130.621 52.9162 128.228 51.2823C125.307 49.3075 114.983 46.8888 111.58 45.9496H110.435L94.6174 44.7725L85.9269 48.9344C85.9269 48.9344 73.8786 53.5015 70.3857 59.8698C66.8928 66.2381 64.2361 84.8477 64.2361 84.8477C64.2361 84.8477 62.6344 90.1674 64.2361 92.5668C65.2332 94.0656 68.9448 95.365 72.2833 96.0018C70.4758 102.023 71.9038 106.493 71.8395 107.259C71.7044 108.841 70.4886 112.527 73.3383 112.302C76.1879 112.077 98.4705 106.191 105.09 105.927C110.694 105.776 116.235 107.135 121.133 109.864C124.471 111.736 124.445 111.871 125.635 108.578C126.825 105.284 123.024 101.572 123.024 101.572C123.024 101.572 126.433 89.254 128.022 81.2004L138.809 81.5735Z" fill="#455A64"/>
<path d="M85.7468 74.4205C85.7468 74.4205 85.5474 74.2469 85.245 73.8866C84.9427 73.5264 84.4667 72.9668 83.9585 72.3621C82.5754 70.682 81.0704 69.106 79.4557 67.647C78.5565 66.8558 77.5925 66.1414 76.5739 65.5114C75.742 65.0041 74.8657 64.5735 73.9558 64.2249C72.3927 63.6331 71.3827 63.4851 71.3827 63.4208C71.6273 63.4158 71.8716 63.4417 72.1096 63.498C72.3943 63.5387 72.6759 63.5989 72.9523 63.6781C73.3235 63.7636 73.6888 63.8732 74.0459 64.0061C74.9861 64.333 75.8911 64.7532 76.7476 65.2605C77.7892 65.8857 78.773 66.6026 79.6873 67.4026C81.3323 68.87 82.8395 70.4849 84.1901 72.227C84.7111 72.8703 85.1228 73.4364 85.393 73.8223C85.5291 74.0104 85.6476 74.2106 85.7468 74.4205Z" fill="#263238"/>
<path d="M82.8457 92.2068C82.5891 92.2543 82.3281 92.2737 82.0673 92.2647C81.5656 92.2647 80.8387 92.2969 79.9381 92.2647C77.5982 92.2505 75.262 92.0764 72.9459 91.7437C70.6291 91.4185 68.3567 90.8315 66.1723 89.994C65.5134 89.7406 64.8692 89.4507 64.2425 89.1256C64.0021 89.0231 63.7753 88.8913 63.5671 88.7332C63.5671 88.6818 64.5835 89.1706 66.2688 89.7753C68.4643 90.5427 70.7282 91.0984 73.0295 91.4349C75.3334 91.7565 77.6521 91.9604 79.9767 92.046L82.0995 92.1296C82.3503 92.1283 82.6005 92.1542 82.8457 92.2068Z" fill="#263238"/>
<path d="M116.295 98.2409C116.244 98.4127 116.165 98.5755 116.064 98.7233C115.822 99.1385 115.558 99.54 115.273 99.9262C113.375 102.497 110.81 104.499 107.856 105.716C107.412 105.904 106.956 106.063 106.492 106.192C106.327 106.253 106.153 106.29 105.977 106.301C105.977 106.243 106.666 106.005 107.759 105.503C109.176 104.839 110.516 104.022 111.754 103.065C112.984 102.098 114.102 100.995 115.086 99.7783C115.813 98.8455 116.218 98.2151 116.295 98.2409Z" fill="#263238"/>
<path d="M116.553 103.181C116.255 103.517 115.9 103.798 115.504 104.011C114.598 104.596 113.627 105.073 112.609 105.432C111.591 105.79 110.536 106.032 109.464 106.153C109.021 106.229 108.569 106.229 108.126 106.153C109.604 105.904 111.063 105.553 112.494 105.104C113.889 104.558 115.246 103.915 116.553 103.181Z" fill="#263238"/>
<path d="M135.079 58.1719C135.079 58.3569 135.053 58.5411 135.001 58.7187C134.901 59.2076 134.774 59.6908 134.622 60.166C134.13 61.7367 133.463 63.2471 132.634 64.6689C131.8 66.0935 130.812 67.4222 129.688 68.6313C129.257 69.0881 128.89 69.4419 128.62 69.6734C128.35 69.905 128.202 70.0272 128.183 70.0079C128.164 69.9886 128.697 69.4226 129.514 68.4705C130.574 67.2342 131.521 65.906 132.345 64.5016C133.16 63.091 133.847 61.6098 134.397 60.076C134.821 58.9117 135.027 58.1591 135.079 58.1719Z" fill="#263238"/>
<path d="M139.131 78.7431C138.619 78.8432 138.096 78.8842 137.574 78.8654C136.61 78.8654 135.278 78.8654 133.811 78.8654C132.345 78.8654 131.013 78.711 130.055 78.6081C129.532 78.5856 129.015 78.5036 128.511 78.3636C129.029 78.3132 129.55 78.3132 130.068 78.3636L133.824 78.5244L137.574 78.6531C138.095 78.6301 138.617 78.6603 139.131 78.7431Z" fill="#263238"/>
<path d="M126.588 60.3013C126.686 60.5772 126.759 60.8614 126.806 61.1504C126.935 61.7936 127.102 62.5463 127.295 63.4854C127.694 65.4602 128.266 68.1941 128.582 71.2625C128.88 73.8857 128.867 76.5351 128.543 79.1553C128.395 80.1523 128.247 80.9564 128.106 81.5032C128.051 81.7926 127.972 82.0768 127.868 82.3523C127.874 82.0577 127.911 81.7646 127.977 81.4774C128.08 80.9307 128.196 80.1202 128.305 79.1231C128.561 76.5189 128.542 73.8948 128.247 71.2946C127.906 68.252 127.372 65.5053 127.038 63.5304C126.864 62.5398 126.729 61.7293 126.652 61.1761C126.597 60.8879 126.576 60.5944 126.588 60.3013Z" fill="#263238"/>
<path d="M85.7468 76.6464C85.7591 76.896 85.744 77.1462 85.7018 77.3925C85.6503 77.8686 85.5667 78.5504 85.4574 79.3931C85.2322 81.0849 84.8913 83.4199 84.4217 85.9801C84.0413 88.1732 83.5344 90.3425 82.9036 92.477C82.7087 93.1224 82.4767 93.756 82.2089 94.3747C82.1159 94.6019 81.9972 94.8177 81.8551 95.0179C81.8036 95.0179 82.2539 94.0402 82.6785 92.3934C83.103 90.7467 83.6241 88.4566 84.0936 85.9029C84.5632 83.3491 84.9363 81.027 85.2258 79.3481C85.3587 78.5633 85.4745 77.9007 85.5732 77.3604C85.6027 77.1164 85.6609 76.8767 85.7468 76.6464Z" fill="#263238"/>
<path d="M83.579 66.399C83.8538 66.795 84.0704 67.2282 84.2223 67.6856C85.0946 69.8003 85.6578 72.0295 85.8948 74.3047C85.9639 74.7739 85.9639 75.2507 85.8948 75.7199C85.7919 75.7199 85.5924 73.5843 84.9363 71.0177C84.2802 68.451 83.489 66.4376 83.579 66.399Z" fill="#263238"/>
<path d="M101.783 89.2092C101.834 89.0824 101.894 88.9598 101.963 88.8426C102.105 88.5853 102.298 88.2443 102.536 87.8069L104.697 83.9795C106.582 80.6989 109.2 76.1446 112.211 70.9084L112.423 71.1336C110.493 72.0599 108.473 72.9733 106.325 73.8224C103.752 74.8516 101.179 75.72 98.8051 76.3954L98.3033 76.5434L98.6378 76.1381L107.643 65.2027L110.281 62.0507L110.995 61.2145C111.075 61.1148 111.163 61.0223 111.259 60.9379C111.195 61.049 111.122 61.1544 111.04 61.2531L110.364 62.115L107.791 65.3313L98.863 76.3633L98.6893 76.106C101.056 75.3984 103.597 74.53 106.177 73.5329C108.312 72.6838 110.358 71.7768 112.256 70.8634L112.738 70.6318L112.468 71.0885L104.852 84.1017L102.607 87.8841L101.963 88.894C101.913 89.0043 101.853 89.1098 101.783 89.2092Z" fill="white"/>
<g opacity="0.3">
<path opacity="0.3" d="M113.323 106.815C114.082 107.015 115.536 104.718 116.379 103.309C113.806 104.738 111.323 106.449 108.441 105.825C110.043 105.396 111.515 104.58 112.726 103.448C113.938 102.316 114.852 100.903 115.388 99.3341C113.376 102.405 110.404 104.723 106.936 105.928C106.936 105.928 112.121 106.5 113.323 106.815Z" fill="black"/>
</g>
<path d="M83.579 38.5328C83.579 38.5328 84.0164 38.713 84.8655 38.8931C86.0268 39.1708 87.2477 39.0508 88.3327 38.5521C89.0631 38.1933 89.6716 37.6273 90.0823 36.9247C90.3212 36.5332 90.4705 36.0937 90.5195 35.6377C90.5684 35.1817 90.5158 34.7206 90.3654 34.2873C90.2133 33.8059 89.936 33.3735 89.5618 33.0344C89.1877 32.6953 88.7302 32.4617 88.2362 32.3575C87.7039 32.2972 87.1664 32.4098 86.7031 32.6785C86.2397 32.9473 85.8751 33.358 85.6631 33.8499C85.5659 34.0973 85.5209 34.3622 85.5309 34.6279C85.5408 34.8935 85.6056 35.1543 85.721 35.3937C85.9637 35.8641 86.3456 36.2483 86.8146 36.4937C87.2612 36.7479 87.7673 36.8789 88.2812 36.8732C88.7773 36.8615 89.2656 36.7477 89.7157 36.5387C90.5608 36.1317 91.2971 35.5298 91.8642 34.7826C92.3783 34.1422 92.7946 33.429 93.0992 32.6663C93.5395 31.5529 93.758 30.3642 93.7425 29.167C93.7425 28.3243 93.6332 27.8804 93.6653 27.8483C93.7112 27.9549 93.7416 28.0675 93.7554 28.1828C93.8159 28.5079 93.8546 28.8367 93.8712 29.167C93.9305 30.3892 93.731 31.6101 93.2858 32.7499C92.9868 33.543 92.5705 34.2868 92.0507 34.9563C91.4649 35.747 90.697 36.3847 89.8122 36.8153C89.3248 37.0472 88.7949 37.1764 88.2555 37.1949C87.6842 37.2028 87.1209 37.0609 86.6216 36.7832C86.0925 36.5047 85.6632 36.0686 85.393 35.5352C85.2545 35.2559 85.1766 34.9505 85.1645 34.639C85.1523 34.3275 85.206 34.017 85.3222 33.7277C85.5594 33.1669 85.9726 32.6985 86.4995 32.3933C87.0263 32.0881 87.6382 31.9627 88.2426 32.0359C88.7913 32.145 89.3005 32.3996 89.7169 32.7731C90.1334 33.1466 90.4418 33.6251 90.6098 34.1587C90.7704 34.6336 90.8242 35.1381 90.7675 35.6362C90.7108 36.1343 90.545 36.6138 90.2818 37.0405C89.833 37.7786 89.1777 38.369 88.397 38.7387C87.261 39.2429 85.9844 39.3339 84.7883 38.996C84.4681 38.915 84.1559 38.8052 83.8556 38.6679C83.7589 38.6328 83.6661 38.5875 83.579 38.5328Z" fill="#263238"/>
<path d="M134.011 24.3362C134.146 23.0287 133.999 21.7076 133.581 20.4615C133.162 19.2155 132.482 18.0735 131.586 17.1123C130.672 16.1652 129.533 15.4653 128.275 15.0783C127.017 14.6913 125.682 14.6298 124.394 14.8995C122.54 15.2442 120.834 16.1411 119.499 17.4726C119.088 17.8846 118.752 18.3658 118.508 18.8942C118.463 18.9714 118.424 19.055 118.379 19.1386C118.334 19.2222 118.283 19.3509 118.238 19.4602V19.5117C117.442 21.3331 117.244 23.36 117.672 25.3011C118.017 26.766 118.73 28.1188 119.742 29.2319C120.755 30.345 122.035 31.1817 123.461 31.6629C124.904 32.097 126.437 32.1275 127.896 31.7509C129.354 31.3744 130.682 30.6053 131.734 29.5273C133.044 28.0955 133.845 26.2703 134.011 24.3362Z" fill="#263238"/>
<path d="M132.859 28.6335C132.801 28.6335 132.686 27.9002 132.216 26.8002C131.634 25.3644 130.73 24.0817 129.573 23.0511C128.417 22.0205 127.039 21.2696 125.545 20.8564C124.4 20.5348 123.616 20.5091 123.654 20.4447C123.829 20.4157 124.007 20.4157 124.182 20.4447C124.661 20.4703 125.137 20.5392 125.603 20.6506C127.161 21.0096 128.604 21.7553 129.798 22.8188C130.992 23.8824 131.899 25.2295 132.435 26.7359C132.597 27.1874 132.72 27.6523 132.801 28.1253C132.848 28.2904 132.868 28.4621 132.859 28.6335Z" fill="#455A64"/>
<path d="M130.344 23.5515C130.227 24.0822 130.03 24.5919 129.759 25.0631C129.159 26.233 128.387 27.3063 127.469 28.2473C126.544 29.1798 125.484 29.9671 124.323 30.5823C123.859 30.8704 123.353 31.0872 122.824 31.2256C122.76 31.1162 125.114 30.2028 127.231 28.0093C129.347 25.8158 130.241 23.4871 130.344 23.5515Z" fill="#455A64"/>
<path d="M123.403 20.7858C123.307 20.7279 124.079 19.4993 124.69 17.8526C125.033 16.8462 125.26 15.8037 125.365 14.7456C125.478 15.0496 125.517 15.3756 125.481 15.6976C125.447 16.4754 125.287 17.2425 125.005 17.9683C124.656 19.0011 124.112 19.9575 123.403 20.7858Z" fill={color}/>
<path d="M123.5 18.5466C123.657 14.8756 122.417 11.2815 120.03 8.48839C117.642 5.69527 114.285 3.91071 110.635 3.49424C108.75 3.28409 106.842 3.45608 105.026 3.99991C103.209 4.54375 101.521 5.44828 100.062 6.65944C98.6024 7.87059 97.4024 9.3635 96.5333 11.0489C95.6642 12.7344 95.1439 14.5777 95.0034 16.4688L93.7168 48.2202C93.6938 48.8349 93.8772 49.4395 94.2379 49.9377C96.2402 52.686 99.2446 54.5348 102.6 55.0838C105.508 55.3678 108.43 54.7045 110.931 53.1926C111.338 52.9516 111.674 52.6078 111.907 52.1958C112.139 51.7838 112.26 51.3179 112.256 50.8447V43.7689C112.256 43.7569 112.26 43.7455 112.269 43.737C112.277 43.7286 112.289 43.7238 112.301 43.7238C112.965 43.7618 113.631 43.7316 114.288 43.6338C116.16 43.1385 120.914 41.2087 122.387 34.1521C122.818 31.7592 123.236 25.1336 123.5 18.5466Z" fill="#FFC69D"/>
<path d="M113.266 29.1094C113.937 28.9411 114.626 28.8547 115.318 28.8521C115.639 28.8521 115.961 28.7878 116.012 28.5755C116.05 28.2521 115.989 27.9248 115.839 27.6363C115.575 26.858 115.292 26.041 115.002 25.1919C113.844 21.7119 113.021 18.8622 113.163 18.8172C113.304 18.7722 114.359 21.5575 115.517 25.0311C115.794 25.8931 116.064 26.71 116.315 27.4884C116.498 27.8758 116.537 28.3162 116.424 28.7299C116.372 28.8393 116.295 28.9355 116.201 29.0112C116.106 29.087 115.995 29.1403 115.877 29.1673C115.698 29.206 115.514 29.2211 115.33 29.2123C114.641 29.263 113.947 29.2284 113.266 29.1094Z" fill="#263238"/>
<path d="M120.444 23.2814C120.429 23.5817 120.298 23.8644 120.078 24.0697C119.859 24.2749 119.568 24.3867 119.267 24.3813C119.119 24.3865 118.972 24.3621 118.834 24.3097C118.696 24.2573 118.569 24.1779 118.462 24.0761C118.355 23.9743 118.269 23.8521 118.21 23.7168C118.15 23.5814 118.118 23.4356 118.116 23.2878C118.127 22.9863 118.258 22.7017 118.478 22.4957C118.699 22.2898 118.991 22.1791 119.293 22.1878C119.441 22.1827 119.588 22.207 119.726 22.2594C119.864 22.3118 119.991 22.3913 120.098 22.4931C120.205 22.5949 120.291 22.717 120.351 22.8524C120.41 22.9877 120.442 23.1335 120.444 23.2814Z" fill="#263238"/>
<path d="M121.602 19.7886C121.486 19.9108 120.554 19.8529 119.37 20.2517C118.186 20.6505 117.402 21.2745 117.247 21.2423C117.093 21.2102 117.247 21.0365 117.614 20.7535C118.113 20.3625 118.677 20.0621 119.28 19.8658C119.859 19.6598 120.473 19.5681 121.088 19.5956C121.493 19.6278 121.66 19.7243 121.602 19.7886Z" fill="#263238"/>
<path d="M108.377 22.4901C108.362 22.7904 108.231 23.0731 108.011 23.2784C107.791 23.4837 107.5 23.5955 107.2 23.5901C107.052 23.5944 106.905 23.5694 106.767 23.5167C106.629 23.464 106.503 23.3845 106.396 23.2829C106.289 23.1812 106.203 23.0594 106.144 22.9245C106.084 22.7895 106.051 22.6441 106.048 22.4965C106.062 22.1957 106.192 21.912 106.412 21.7064C106.632 21.5007 106.924 21.3895 107.225 21.3965C107.373 21.3914 107.52 21.4157 107.659 21.4682C107.797 21.5206 107.923 21.6 108.03 21.7018C108.138 21.8036 108.223 21.9257 108.283 22.0611C108.342 22.1964 108.374 22.3423 108.377 22.4901Z" fill="#263238"/>
<path d="M108.788 20.818C108.602 20.818 107.92 20.0847 106.698 19.5314C105.476 18.9782 104.408 18.8367 104.318 18.6952C104.228 18.5537 104.491 18.5601 104.961 18.6051C105.633 18.6869 106.285 18.8827 106.891 19.1841C107.494 19.4583 108.04 19.8447 108.499 20.3227C108.821 20.6186 108.872 20.8115 108.788 20.818Z" fill="#263238"/>
<path d="M112.198 43.6792C112.198 43.6792 107.341 43.8207 101.449 39.9354C101.449 39.9354 103.07 46.323 112.198 45.9499V43.6792Z" fill="#EB996E"/>
<path d="M112.603 32.0492C112.374 31.7426 112.073 31.4975 111.726 31.3359C111.379 31.1742 110.997 31.1011 110.615 31.1229C110.339 31.1343 110.067 31.2005 109.817 31.3175C109.566 31.4345 109.341 31.6002 109.155 31.8048C108.981 32.002 108.872 32.2476 108.842 32.5086C108.813 32.7697 108.863 33.0336 108.988 33.265C109.181 33.4979 109.438 33.669 109.727 33.757C110.016 33.8451 110.325 33.8462 110.615 33.7603C111.213 33.5826 111.77 33.2898 112.256 32.8983C112.403 32.8071 112.531 32.6893 112.635 32.551C112.68 32.4833 112.704 32.4039 112.704 32.3226C112.704 32.2413 112.68 32.1619 112.635 32.0943" fill="#EB996E"/>
<path d="M109.541 29.7718C109.74 29.7718 109.683 31.1098 110.828 32.1198C111.973 33.1297 113.401 33.0525 113.401 33.239C113.401 33.3227 113.066 33.4835 112.455 33.4706C111.659 33.4394 110.898 33.135 110.3 32.6086C109.74 32.1134 109.39 31.4232 109.322 30.6788C109.277 30.087 109.445 29.759 109.541 29.7718Z" fill="#263238"/>
<path d="M109.631 15.8391C109.457 16.1479 108.248 15.7812 106.769 15.6976C105.289 15.6139 104.048 15.8069 103.906 15.4724C103.848 15.318 104.099 15.0414 104.627 14.8292C105.326 14.5387 106.084 14.4175 106.839 14.4754C107.597 14.5151 108.333 14.7357 108.988 15.1186C109.49 15.3759 109.715 15.6911 109.631 15.8391Z" fill="#263238"/>
<path d="M121.576 16.0191C121.345 16.2893 120.496 15.9548 119.46 15.8905C118.424 15.8262 117.53 15.9805 117.344 15.6782C117.26 15.5303 117.415 15.2537 117.807 15.0349C118.342 14.7467 118.95 14.6194 119.556 14.6685C120.162 14.7176 120.741 14.9409 121.223 15.3115C121.576 15.6074 121.679 15.9033 121.576 16.0191Z" fill="#263238"/>
<path d="M111.69 1.86718C111.046 0.767202 109.76 0.284756 108.422 0.0982099C102.851 -0.635108 96.7144 2.82564 93.6911 7.56005C90.6678 12.2945 90.6292 19.2996 93.0028 24.3813L95.0934 24.7094C99.429 24.3685 106.505 12.1401 112.461 5.34723C113.201 4.51099 112.281 2.84494 111.69 1.86718Z" fill="#263238"/>
<path d="M122.393 5.33459C120.026 2.76154 116.186 -0.416172 111.4 1.47502C110.712 2.83873 112.178 4.56911 113.388 5.56616C115.17 7.03923 116.72 8.02342 117.891 9.07194C120.251 11.2011 123.198 14.7391 123.5 18.7209C123.5 18.9717 123.712 19.0039 123.963 18.9589C124.214 18.9138 124.999 17.2671 125.346 15.0993C126.066 10.4935 124.767 7.93337 122.393 5.33459Z" fill="#263238"/>
<path d="M95.4343 17.447C95.1778 16.3828 94.6755 15.3937 93.9677 14.5587C93.5052 14.01 92.958 13.5387 92.3467 13.1628C91.2038 12.5097 89.9139 12.157 88.5977 12.1379C87.2814 12.1188 85.9819 12.4338 84.8205 13.0535C83.6767 13.6941 82.6831 14.572 81.9064 15.6282C81.1298 16.6844 80.5881 17.8945 80.3177 19.1773C79.8744 21.0722 80.0711 23.0604 80.8773 24.8316C81.5507 26.1789 82.5811 27.3154 83.856 28.1173C85.1309 28.9193 86.6016 29.3559 88.1076 29.3795C89.6142 29.363 91.0918 28.9628 92.4008 28.2166C93.7098 27.4703 94.807 26.4027 95.5887 25.1146C96.6565 23.3006 96.3542 20.0972 95.4343 17.447Z" fill="#263238"/>
<path d="M96.425 22.1555C96.2963 22.0912 91.1245 20.1549 90.8994 25.5583C90.6742 30.9617 96.1484 30.0612 96.1613 29.9197C96.1741 29.7781 96.425 22.1555 96.425 22.1555Z" fill="#FFC69D"/>
<path d="M94.598 27.8036C94.598 27.8036 94.5016 27.8679 94.3407 27.9322C94.1135 28.0225 93.8604 28.0225 93.6332 27.9322C93.0735 27.6942 92.6297 26.7679 92.6425 25.7966C92.6468 25.332 92.7568 24.8744 92.9642 24.4586C93.0221 24.2917 93.1197 24.1413 93.2486 24.0203C93.3774 23.8993 93.5336 23.8113 93.7039 23.7639C93.8129 23.7403 93.9266 23.7558 94.0254 23.8075C94.1241 23.8592 94.2016 23.944 94.2443 24.0469C94.315 24.2013 94.2764 24.3042 94.3021 24.3171C94.3279 24.33 94.4244 24.2335 94.3858 24.0083C94.3656 23.8715 94.2944 23.7474 94.1864 23.661C94.0428 23.5416 93.8578 23.4838 93.6717 23.5002C93.4486 23.5359 93.2382 23.6279 93.0603 23.7675C92.8825 23.907 92.7432 24.0895 92.6554 24.2978C92.4077 24.7566 92.2752 25.2688 92.2694 25.7902C92.2694 26.8773 92.7712 27.9194 93.556 28.1831C93.6996 28.2312 93.8523 28.2463 94.0026 28.2274C94.1529 28.2085 94.2971 28.156 94.4244 28.0738C94.5852 27.9322 94.6173 27.8165 94.598 27.8036Z" fill="#EB996E"/>
<path d="M123.506 6.41481C122.993 6.55604 122.463 6.62531 121.93 6.62065C120.653 6.69258 119.372 6.64952 118.103 6.492C116.837 6.345 115.587 6.08889 114.366 5.72652C113.848 5.60332 113.35 5.40846 112.886 5.14758C113.409 5.22673 113.925 5.345 114.43 5.50138C115.66 5.79763 116.905 6.02526 118.161 6.18323C119.416 6.32737 120.679 6.40253 121.943 6.40838C122.464 6.36872 122.986 6.37087 123.506 6.41481Z" fill="#455A64"/>
<path d="M125.301 10.2942C124.932 10.4616 124.535 10.5555 124.13 10.5708C123.155 10.6781 122.168 10.6259 121.21 10.4164C120.253 10.2058 119.337 9.84313 118.495 9.34213C118.13 9.16068 117.807 8.90678 117.543 8.59595C118.722 9.24635 119.978 9.74765 121.281 10.0883C122.608 10.3171 123.957 10.3862 125.301 10.2942Z" fill="#455A64"/>
<path d="M93.0028 9.42554C93.0028 9.35478 94.1735 9.56063 96.0583 9.50916C97.1629 9.47918 98.2637 9.36737 99.3518 9.17467C100.679 8.92466 101.988 8.5851 103.269 8.15832C104.549 7.71696 105.799 7.19487 107.013 6.59519C108 6.09889 108.958 5.54694 109.882 4.94201C111.464 3.91279 112.346 3.13444 112.391 3.17947C112.21 3.39127 112.01 3.58516 111.792 3.75841C111.599 3.93852 111.349 4.14436 111.046 4.40167C110.744 4.65897 110.403 4.90341 110.017 5.16715C109.099 5.80488 108.141 6.38287 107.148 6.89752C105.93 7.51808 104.671 8.05327 103.379 8.49924C102.081 8.93453 100.752 9.27 99.4032 9.50274C98.2996 9.68333 97.183 9.77369 96.0647 9.7729C95.6352 9.78052 95.2057 9.76334 94.7782 9.72144C94.4546 9.70926 94.1322 9.67487 93.8133 9.61852C93.5377 9.57938 93.2664 9.51478 93.0028 9.42554Z" fill="#455A64"/>
<path d="M112.031 5.08368C111.913 5.34886 111.769 5.60109 111.6 5.83629C111.452 6.0743 111.265 6.36377 111.027 6.67897C110.789 6.99417 110.538 7.37369 110.204 7.74678C109.464 8.65442 108.653 9.5019 107.779 10.2812C106.7 11.2269 105.54 12.075 104.311 12.8157C103.069 13.5413 101.762 14.1507 100.407 14.6361C99.3014 15.0233 98.1652 15.3159 97.0103 15.511C96.5215 15.6139 96.0712 15.646 95.6788 15.6911C95.3419 15.7379 95.0025 15.7637 94.6624 15.7683C94.3735 15.7919 94.083 15.7919 93.794 15.7683C93.794 15.6975 95.0291 15.6718 96.9653 15.2858C98.0999 15.0587 99.2159 14.747 100.304 14.3531C102.972 13.381 105.437 11.9217 107.573 10.0497C108.448 9.29359 109.267 8.47446 110.024 7.59884C111.323 6.08074 111.973 5.03865 112.031 5.08368Z" fill="#455A64"/>
<path d="M108.422 0.117324C108.785 0.046177 109.16 0.0728201 109.509 0.194517C110.366 0.43935 111.136 0.923568 111.728 1.59039C111.898 1.79005 112.049 2.00544 112.178 2.23366C112.291 2.43782 112.382 2.65347 112.449 2.87692C112.579 3.24584 112.68 3.62437 112.751 4.00906C112.847 4.36993 112.82 4.75246 112.674 5.09617C112.616 5.09617 112.674 4.67805 112.519 4.06052C112.418 3.69347 112.296 3.33263 112.153 2.97984C112.088 2.7699 112.002 2.56726 111.895 2.37517C111.774 2.17594 111.634 1.98857 111.477 1.81553C110.921 1.19153 110.221 0.712423 109.438 0.419658C108.834 0.207382 108.422 0.175218 108.422 0.117324Z" fill="#455A64"/>
<path d="M94.1607 21.686C94.1092 21.686 94.2701 21.1263 94.3858 20.2386C94.5308 19.0804 94.4632 17.9054 94.1864 16.7715C93.8929 15.645 93.3325 14.6058 92.5525 13.7417C91.9093 13.0984 91.459 12.7832 91.4976 12.7253C91.5362 12.6674 91.6327 12.7704 91.8578 12.9055C92.1684 13.0868 92.4576 13.3026 92.7198 13.5487C93.5887 14.404 94.2071 15.4806 94.5081 16.6621C94.8064 17.834 94.8437 19.0571 94.6174 20.2451C94.5553 20.7494 94.4004 21.2379 94.1607 21.686Z" fill={color}/>
<path d="M94.5659 20.522C94.5338 20.567 93.8712 20.1425 92.7326 19.5764C89.7636 18.1092 86.4848 17.3789 83.1737 17.4472C81.8872 17.4472 81.1282 17.5758 81.1153 17.5244C81.287 17.4507 81.4695 17.4051 81.6556 17.3893C82.1555 17.299 82.6603 17.2389 83.1673 17.2092C86.5227 17.0105 89.8663 17.7595 92.8162 19.3706C93.2618 19.6085 93.6915 19.8749 94.1028 20.1682C94.4244 20.3547 94.5788 20.477 94.5659 20.522Z" fill="#455A64"/>
<path d="M89.6449 18.3477C89.5891 18.8944 89.4526 19.4298 89.2397 19.9365C88.7919 21.1806 88.1843 22.3613 87.4321 23.4487C86.6767 24.5351 85.7813 25.517 84.769 26.3691C84.3709 26.7466 83.9176 27.0612 83.4246 27.3019C84.8098 26.091 86.0607 24.7344 87.1555 23.2557C88.1572 21.7127 88.9917 20.0674 89.6449 18.3477Z" fill="#455A64"/>
<path d="M93.2022 28.8454C93.2408 28.7425 93.4338 28.6782 93.7554 28.7618C94.2208 28.9031 94.6216 29.2037 94.8876 29.6109C95.2446 30.1462 95.3829 30.798 95.274 31.4322C95.1652 32.0663 94.8174 32.6346 94.3022 33.0202C93.8704 33.3293 93.3558 33.5018 92.8249 33.5156C92.294 33.5293 91.7712 33.3837 91.3239 33.0974C90.9289 32.8392 90.6058 32.4851 90.3848 32.0682C90.181 31.6908 90.0749 31.2684 90.076 30.8395C90.0888 30.4927 90.1697 30.1517 90.314 29.836C90.4963 29.3716 90.8513 28.9959 91.3046 28.7875C91.6455 28.6589 91.8514 28.7489 91.8771 28.8519C91.9157 29.0834 91.5105 29.3922 91.4461 30.1834C91.417 30.3753 91.417 30.5705 91.4461 30.7623C91.4973 30.9499 91.5823 31.1264 91.697 31.2834C91.8151 31.4559 91.9735 31.597 92.1585 31.6945C92.3434 31.792 92.5493 31.843 92.7584 31.843C92.9564 31.8462 93.1513 31.7936 93.3208 31.6912C93.4903 31.5888 93.6275 31.4408 93.7168 31.2641C93.8944 30.9231 93.9336 30.5268 93.8262 30.1577C93.6332 29.4437 93.1379 29.0641 93.2022 28.8454Z" fill="#455A64"/>
<path d="M98.6764 20.2968C99.7782 20.2185 100.869 20.0267 101.931 19.7243C104.467 18.88 106.703 17.3153 108.364 15.2215C109.992 13.1062 111.214 10.7069 111.966 8.14558C112.275 7.16139 112.487 6.35732 112.609 5.80411C112.664 5.50978 112.746 5.22119 112.854 4.94214C112.842 5.24126 112.801 5.53852 112.732 5.82984C112.635 6.39591 112.455 7.21285 112.178 8.20991C111.479 10.8321 110.263 13.2881 108.602 15.4337C106.905 17.57 104.599 19.1403 101.989 19.9366C101.2 20.167 100.386 20.2988 99.5641 20.329C99.3498 20.3393 99.1351 20.3393 98.9208 20.329C98.76 20.3161 98.6764 20.3097 98.6764 20.2968Z" fill="#263238"/>
<path d="M112.018 48.433C112.136 48.4663 112.247 48.5208 112.346 48.5938C112.638 48.7884 112.897 49.0296 113.111 49.3078C113.451 49.7512 113.692 50.2623 113.819 50.8066C113.998 51.5296 113.978 52.2876 113.761 53.0001C113.442 53.9112 112.921 54.7386 112.239 55.4213C111.556 56.1039 110.729 56.6241 109.818 56.9433C108.766 57.3437 107.668 57.6116 106.55 57.741C105.356 57.8989 104.15 57.9463 102.948 57.8825C100.602 57.7996 98.3233 57.0815 96.3542 55.8048C94.7423 54.7541 93.4829 53.2437 92.739 51.4692C92.4512 50.7896 92.3045 50.0587 92.308 49.3207C92.3152 48.7583 92.4658 48.207 92.7455 47.719C92.9207 47.4125 93.1572 47.1453 93.4402 46.9342C93.5347 46.8589 93.6412 46.8001 93.7554 46.7605C93.3851 47.0308 93.0797 47.3802 92.8613 47.7833C92.6131 48.2573 92.487 48.7857 92.4946 49.3207C92.5148 50.0323 92.6721 50.7334 92.9577 51.3856C93.7135 53.0932 94.9551 54.5406 96.5278 55.5475C98.4563 56.7738 100.677 57.4643 102.96 57.548C104.144 57.6105 105.33 57.5653 106.505 57.4129C107.605 57.2969 108.685 57.0418 109.721 56.6539C110.602 56.3622 111.405 55.8768 112.073 55.2333C112.741 54.5898 113.256 53.8047 113.581 52.9358C113.768 52.3427 113.817 51.7148 113.726 51.0997C113.634 50.4845 113.404 49.8982 113.053 49.385C112.762 49.0133 112.412 48.6917 112.018 48.433Z" fill="#263238"/>
<path d="M97.8659 56.7695C97.7666 56.2355 97.8797 55.6839 98.1811 55.2321C98.5928 54.4345 99.2167 54.0292 99.2682 54.1C99.3196 54.1707 98.8501 54.6596 98.477 55.3865C98.1039 56.1134 97.9624 56.7824 97.8659 56.7695Z" fill="#263238"/>
<path d="M103.9 57.9145C103.757 57.4793 103.719 57.0163 103.79 56.5637C103.79 55.8239 103.912 55.2257 104.009 55.2257C104.152 55.6608 104.192 56.1234 104.125 56.5766C104.073 57.3163 103.996 57.9145 103.9 57.9145Z" fill="#263238"/>
<path d="M109.959 57.0206C109.539 56.0804 109.33 55.0592 109.348 54.0294C109.607 54.4769 109.768 54.9749 109.818 55.4896C109.972 55.9842 110.02 56.506 109.959 57.0206Z" fill="#263238"/>
<path d="M113.497 53.1608C113.413 53.2058 113.163 52.8713 112.745 52.6012C112.326 52.331 111.928 52.2152 111.934 52.1187C111.94 52.0222 112.436 51.9901 112.931 52.3181C113.426 52.6462 113.581 53.1287 113.497 53.1608Z" fill="#263238"/>
<path d="M94.6689 50.7549C94.6689 50.845 94.328 50.9672 93.987 51.2502C93.6461 51.5333 93.4338 51.8356 93.3438 51.8034C93.2537 51.7713 93.3438 51.321 93.7747 50.9865C94.2057 50.652 94.6624 50.6713 94.6689 50.7549Z" fill="#263238"/>
<path d="M105.25 93.8025C105.033 93.7919 104.821 93.7289 104.634 93.6189C104.446 93.5088 104.287 93.355 104.172 93.1706C104.056 92.9861 103.987 92.7764 103.97 92.5594C103.952 92.3424 103.988 92.1244 104.073 91.9241C105.81 88.0131 110.551 80.4869 110.86 79.9981C111.31 79.3162 111.548 78.4735 112.989 78.2999C113.542 78.2355 122.226 78.0361 124.265 77.9911C124.43 77.9846 124.594 78.0108 124.749 78.0682C124.904 78.1256 125.045 78.2131 125.166 78.3256C125.571 78.7115 125.996 79.4449 125.352 80.5963C124.632 81.8828 119.383 90.0329 117.254 93.1721C117.046 93.4777 116.764 93.7258 116.434 93.8934C116.105 94.061 115.738 94.1425 115.369 94.1305L105.25 93.8025Z" fill="#263238"/>
<path d="M118.65 80.7953C118.566 80.6974 118.456 80.6267 118.332 80.5923C118.208 80.5579 118.077 80.5614 117.955 80.6023C117.721 80.679 117.511 80.8141 117.344 80.9947C117.246 81.0752 117.179 81.1876 117.156 81.3123C117.132 81.4369 117.153 81.5659 117.215 81.6766C117.288 81.7563 117.38 81.8164 117.482 81.8512C117.585 81.8861 117.694 81.8946 117.801 81.876C118.075 81.8596 118.334 81.7428 118.527 81.5479C118.62 81.4489 118.682 81.3244 118.704 81.1903C118.725 81.0562 118.707 80.9186 118.65 80.7953Z" fill="white"/>
<path d="M123.114 82.5642C123.202 82.3024 123.316 82.0502 123.455 81.8115C123.583 81.5607 123.725 81.2712 123.892 80.956C123.982 80.7887 124.072 80.6086 124.169 80.4221C124.287 80.2517 124.362 80.0553 124.388 79.8496C124.391 79.7448 124.369 79.6409 124.323 79.5467C124.277 79.4525 124.208 79.371 124.124 79.3092C124.024 79.2456 123.912 79.2026 123.795 79.1828C123.677 79.1629 123.558 79.1665 123.442 79.1935L121.667 79.2642L117.608 79.4057L113.542 79.528H113.092C112.97 79.5383 112.854 79.5829 112.757 79.6566C112.58 79.8535 112.425 80.0692 112.294 80.2999C111.998 80.763 111.722 81.2004 111.458 81.5864L110.236 83.4583C110.096 83.6891 109.932 83.9046 109.747 84.1015C109.846 83.8438 109.973 83.5978 110.126 83.3682L111.259 81.4384L112.056 80.1133L112.275 79.7531C112.351 79.6103 112.451 79.4819 112.571 79.3736C112.709 79.2574 112.88 79.1857 113.06 79.1677H113.536L117.582 79.0584L121.647 78.9426L123.416 78.904C123.715 78.8545 124.021 78.9213 124.272 79.0905C124.384 79.1804 124.473 79.2953 124.532 79.4261C124.591 79.5569 124.619 79.6997 124.613 79.8432C124.607 79.9765 124.569 80.1066 124.503 80.2227L124.343 80.5121L124.04 81.0332C123.86 81.342 123.699 81.6186 123.551 81.863C123.432 82.1124 123.286 82.3476 123.114 82.5642Z" fill="#455A64"/>
<path d="M137.652 81.5868C137.652 81.5868 136.77 87.7107 135.87 91.6474C135.066 95.1532 133.786 107.002 130.531 108.494C127.276 109.987 123.217 109.054 121.718 106.166C120.219 103.278 118.611 95.5906 118.611 95.5906L111.793 92.7217C111.793 92.7217 110.223 87.0031 111.117 86.3598C112.011 85.7165 120.811 86.6814 120.811 86.6814L123.172 86.1475C123.172 86.1475 119.415 85.6072 118.611 85.3949C118.428 85.3687 118.253 85.3056 118.095 85.2095C117.938 85.1134 117.801 84.9864 117.694 84.836C117.587 84.6857 117.512 84.5152 117.473 84.3348C117.433 84.1544 117.431 83.968 117.466 83.7868C117.653 83.0341 117.807 82.2815 119.84 82.2815C121.872 82.2815 125.063 81.9792 125.629 82.1786C126.35 82.423 127.16 82.8219 127.321 84.6423C127.482 86.4627 126.626 89.7884 126.626 89.7884L126.813 90.0714L128.016 81.2073L137.652 81.5868Z" fill="#FFC69D"/>
<path d="M126.202 92.1619C126.031 92.2664 125.844 92.3404 125.648 92.3806C125.136 92.5526 124.614 92.6944 124.085 92.8051C122.339 93.1851 120.549 93.3215 118.765 93.2104C116.979 93.0958 115.22 92.712 113.549 92.0718C113.042 91.89 112.548 91.6751 112.069 91.4285C111.886 91.3508 111.714 91.2468 111.561 91.1198C111.561 91.0683 112.346 91.4285 113.632 91.866C115.297 92.4239 117.027 92.7675 118.778 92.8887C120.53 92.9859 122.288 92.878 124.014 92.5671C125.378 92.3355 126.189 92.1104 126.202 92.1619Z" fill="#EB996E"/>
<path d="M124.909 89.7044C124.734 89.7898 124.547 89.8484 124.355 89.8781C123.995 89.9681 123.468 90.0839 122.812 90.1933C121.09 90.4819 119.339 90.5467 117.601 90.3863C115.857 90.2195 114.159 89.7264 112.597 88.9325C112.126 88.691 111.677 88.4114 111.252 88.0963C110.956 87.8711 110.802 87.7296 110.815 87.7103C110.828 87.691 111.509 88.1606 112.699 88.7202C114.261 89.4335 115.931 89.8834 117.64 90.0518C119.353 90.2084 121.079 90.1761 122.786 89.9553C124.092 89.8009 124.902 89.6529 124.909 89.7044Z" fill="#EB996E"/>
<path d="M127.038 83.3813C127.076 83.3813 127.199 83.6837 127.166 84.2047C127.157 84.5228 127.071 84.8339 126.916 85.1117C126.808 85.2811 126.657 85.4187 126.478 85.5105C126.306 85.6079 126.125 85.6876 125.938 85.7485C125.301 85.946 124.642 86.0585 123.976 86.083C123.709 86.1207 123.441 86.1422 123.172 86.1474C123.172 86.1023 123.442 85.9415 123.944 85.8515C124.583 85.754 125.214 85.6143 125.835 85.4334C126.15 85.3524 126.436 85.1828 126.658 84.9445C126.8 84.71 126.894 84.4499 126.935 84.179C126.952 83.9112 126.986 83.6447 127.038 83.3813Z" fill="#EB996E"/>
<path d="M123.796 93.6864C123.879 93.8601 123.922 94.0502 123.922 94.2428C123.922 94.4354 123.879 94.6256 123.796 94.7992C123.662 95.1995 123.425 95.557 123.107 95.8349C122.717 96.173 122.241 96.3958 121.731 96.4782C121.23 96.5648 120.717 96.5429 120.226 96.4138C119.837 96.3031 119.462 96.15 119.106 95.9571C118.77 95.8107 118.45 95.6273 118.154 95.4103C118.522 95.45 118.878 95.5615 119.203 95.7384C119.966 96.1205 120.827 96.2639 121.673 96.1501C122.124 96.0783 122.549 95.8923 122.908 95.6097C123.188 95.3698 123.408 95.0681 123.551 94.7285C123.681 94.3942 123.763 94.0434 123.796 93.6864Z" fill="#EB996E"/>
<path d="M126.633 82.7637C126.633 82.7637 126.678 82.7637 126.755 82.8344C126.864 82.9237 126.956 83.0329 127.025 83.1561C127.266 83.6153 127.398 84.1237 127.411 84.642C127.448 86.3965 127.232 88.1471 126.768 89.8396V89.7495C127.099 90.628 127.505 91.476 127.983 92.2839C128.645 93.4063 129.385 94.481 130.196 95.5003C130.935 96.2981 131.583 97.1763 132.126 98.1183C132.463 98.7952 132.529 99.5748 132.313 100.299C132.247 100.492 132.149 100.673 132.023 100.833C131.939 100.936 131.882 100.981 131.875 100.974C131.999 100.746 132.103 100.507 132.184 100.26C132.343 99.5704 132.247 98.8461 131.914 98.2213C131.356 97.3167 130.709 96.4698 129.984 95.6932C129.149 94.6801 128.392 93.6049 127.72 92.4769C127.234 91.6574 126.823 90.7964 126.491 89.9039V89.8524V89.8074C126.949 88.1403 127.196 86.4221 127.224 84.6935C127.228 84.1997 127.134 83.7101 126.948 83.2526C126.853 83.0834 126.747 82.9201 126.633 82.7637Z" fill="#EB996E"/>
<path d="M66.4361 94.2074C66.4361 94.2074 65.4391 107.883 71.8009 110.173C80.5686 113.338 90.5649 104.602 90.6035 104.461C90.642 104.319 101.243 95.7834 101.243 95.7834L97.9431 86.6941C97.9431 86.6941 96.618 86.6941 94.4759 88.2251C93.6975 88.7783 81.4112 95.2559 81.4112 95.2559C81.4112 95.2559 76.0464 95.2109 66.4361 94.2074Z" fill="#FFC69D"/>
<path d="M81.8551 95.0499C81.9001 95.1528 79.5844 96.092 76.7862 97.3464C75.3903 97.9896 74.1617 98.6329 73.2804 99.096C72.8209 99.3867 72.3391 99.6405 71.8395 99.8551C72.2358 99.4781 72.679 99.1538 73.1582 98.8902C74.2817 98.2006 75.4484 97.584 76.6511 97.044C78.0534 96.4008 79.3528 95.899 80.2984 95.5517C80.7999 95.3347 81.3213 95.1667 81.8551 95.0499Z" fill="#EB996E"/>
<path d="M109.695 81.741C109.695 81.741 108.036 79.3931 107.38 79.3995C106.724 79.406 102.767 80.0428 102.767 80.0428L97.1775 86.1345C97.1775 86.1345 96.7916 88.1093 98.0009 88.8233C98.0009 88.8233 97.5957 91.9303 99.088 92.8244C99.088 92.8244 100.201 95.7448 101.243 95.8027C101.684 95.8422 102.127 95.8422 102.568 95.8027C102.568 95.8027 102.343 97.5588 103.932 97.6682C105.507 97.6444 107.078 97.4873 108.628 97.1986C108.857 97.1602 109.076 97.0767 109.272 96.9531C109.469 96.8294 109.639 96.668 109.773 96.4782L111.136 94.5484C111.351 94.2495 111.467 93.8906 111.467 93.5224C111.467 93.1541 111.351 92.7952 111.136 92.4964C111.495 92.0716 111.774 91.5856 111.96 91.0619C112.05 90.4541 112.05 89.8364 111.96 89.2286C111.96 89.2286 114.32 87.228 114.378 86.707C114.436 86.186 114.32 85.6842 113.278 85.7421C113.278 85.7421 113.06 84.1275 111.741 84.1661C110.422 84.2047 104.176 84.1661 104.176 84.1661L105.366 83.0082C105.366 83.0082 108.473 84.1918 108.801 83.4071L109.695 81.741Z" fill="#FFC69D"/>
<path d="M100.728 88.7395C101.065 88.6905 101.405 88.6669 101.745 88.6688C102.388 88.6302 103.34 88.5723 104.511 88.4372C105.889 88.2737 107.258 88.031 108.608 87.7103C109.374 87.5238 110.171 87.3051 110.995 87.0671C111.813 86.7524 112.551 86.2603 113.156 85.6262L113.195 85.594H113.24C113.546 85.5202 113.868 85.5519 114.153 85.684C114.307 85.7809 114.421 85.9291 114.475 86.1022C114.519 86.2547 114.538 86.4131 114.533 86.5717C114.542 86.6422 114.542 86.7136 114.533 86.784C114.517 86.869 114.484 86.95 114.436 87.022C114.364 87.1345 114.285 87.2419 114.198 87.3437C114.037 87.5366 113.857 87.7168 113.684 87.8969C113.33 88.2507 112.976 88.5787 112.616 88.8939C111.381 89.8781 110.093 90.793 108.756 91.6342C107.664 92.2924 106.453 92.7295 105.193 92.9207C104.359 93.0472 103.51 93.0254 102.684 92.8564C102.465 92.8111 102.25 92.7487 102.041 92.6699C101.965 92.6501 101.893 92.6175 101.828 92.5734C102.919 92.8524 104.057 92.8985 105.167 92.7085C106.389 92.4992 107.56 92.0628 108.621 91.4219C109.837 90.6951 111.066 89.6851 112.423 88.6623C112.777 88.3471 113.13 88.0191 113.471 87.6717C113.645 87.498 113.812 87.3244 113.967 87.1378C114.121 86.9513 114.115 86.9513 114.179 86.8612C114.243 86.7712 114.23 86.7583 114.23 86.7519C114.237 86.6963 114.237 86.6402 114.23 86.5846C114.23 86.3144 114.185 86.0764 114.012 85.9864C113.796 85.8963 113.557 85.8783 113.33 85.9349L113.42 85.8963C113.082 86.2134 112.723 86.5078 112.346 86.7776C111.983 87.0678 111.568 87.2861 111.123 87.4208C110.294 87.6782 109.483 87.8904 108.705 88.0641C107.342 88.3696 105.96 88.5845 104.569 88.7074C103.385 88.8232 102.433 88.8425 101.777 88.8425C101.449 88.8425 101.198 88.8425 101.024 88.8425C100.921 88.823 100.821 88.7883 100.728 88.7395Z" fill="#EB996E"/>
<path d="M111.953 89.2156C112.053 89.5316 112.099 89.8621 112.088 90.1933C112.088 90.3477 112.088 90.5214 112.088 90.7144C112.088 90.8109 112.088 90.9074 112.088 91.0103C112.079 91.1272 112.046 91.2409 111.992 91.3448C111.899 91.5659 111.791 91.7807 111.67 91.988L111.477 92.3097L111.374 92.4705C111.355 92.5061 111.334 92.5405 111.31 92.5734L111.265 92.612C111.233 92.612 111.265 92.657 111.111 92.612C111.081 92.5935 111.06 92.5653 111.049 92.5324C111.039 92.4995 111.04 92.4639 111.053 92.4319C111.05 92.4213 111.05 92.4103 111.053 92.3997L111.085 92.3547L111.342 92.5091C110.922 93.2738 110.366 93.956 109.702 94.5225C108.584 95.4855 107.185 96.0629 105.714 96.1692C105.285 96.201 104.853 96.1881 104.427 96.1306C104.266 96.1201 104.107 96.0832 103.958 96.0213C103.958 95.957 104.639 96.0728 105.72 95.9377C107.121 95.7628 108.441 95.1813 109.515 94.2652C110.148 93.7146 110.685 93.0624 111.104 92.3354L111.368 92.4898C111.772 91.4462 111.971 90.3345 111.953 89.2156Z" fill="#EB996E"/>
<path d="M105.508 83.0467C105.508 83.1239 104.865 82.8859 104.549 82.1397C104.439 81.8321 104.421 81.4989 104.498 81.1812C104.562 80.9432 104.652 80.8146 104.691 80.8274C104.729 80.8403 104.639 81.4257 104.865 82.0239C105.09 82.6221 105.572 82.963 105.508 83.0467Z" fill="#EB996E"/>
<path d="M102.71 95.8412C102.557 95.9219 102.387 95.9618 102.214 95.957C101.745 95.9914 101.275 95.9027 100.851 95.6997C100.206 95.3949 99.6992 94.8603 99.429 94.2009C99.2746 93.8164 99.196 93.4058 99.1974 92.9915C99.1977 92.9082 99.1786 92.8259 99.1418 92.7511C99.105 92.6764 99.0513 92.6112 98.9851 92.5605C98.6408 92.3338 98.3579 92.0254 98.1615 91.6629C97.9652 91.3005 97.8614 90.8951 97.8594 90.4828C97.8319 89.9739 97.8513 89.4636 97.9173 88.9583L97.9881 89.0998C97.6472 88.8822 97.3684 88.58 97.1791 88.2226C96.9897 87.8653 96.8961 87.4649 96.9074 87.0607C96.8879 86.7569 96.9768 86.456 97.1583 86.2115C97.1146 86.4905 97.0889 86.772 97.0811 87.0542C97.0962 87.417 97.2003 87.7704 97.3843 88.0834C97.5683 88.3964 97.8264 88.6593 98.136 88.8489L98.2132 88.8939V88.984C98.1709 89.4741 98.1709 89.967 98.2132 90.4571C98.2458 90.9827 98.4389 91.4856 98.7664 91.898C98.9045 92.0506 99.0599 92.1866 99.2296 92.3032C99.3269 92.3809 99.4065 92.4785 99.463 92.5894C99.5196 92.7004 99.5519 92.8221 99.5576 92.9465C99.5492 93.328 99.6125 93.7076 99.7442 94.0658C99.9771 94.6666 100.418 95.1641 100.986 95.4681C101.523 95.7266 102.114 95.8544 102.71 95.8412Z" fill="#EB996E"/>
<g opacity="0.3">
<path opacity="0.3" d="M85.6246 77.457C84.872 84.282 83.8106 89.4925 82.2089 94.1818C84.4572 92.2778 85.9421 89.6256 86.3901 86.7136C86.8146 83.8382 86.1392 80.3453 85.6246 77.457Z" fill="black"/>
</g>
<g opacity="0.3">
<path opacity="0.3" d="M129.444 73.7067C129.192 71.2853 128.585 68.9141 127.642 66.6694C128.652 71.494 128.768 76.463 127.983 81.3294L128.215 80.8083C129.266 78.5979 129.691 76.1417 129.444 73.7067Z" fill="black"/>
</g>
</svg>

)

export default WomanPuff